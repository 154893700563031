<template>
  <section class="mb-20">
    <div>
      <div class="pb-4 pt-32 px-8">
        <h1
          class="flex justify-left text-3xl flex font-bold leading-small mb-4 text-left sm:text-4xxl sm:justify-center"
        >
          Our Process
        </h1>
        <div>
          <h3
            class="sm:block hidden sm:text-center text-left text-base leading-loose text-grey-text xl:w-full lg:max-w-4xl mx-auto font-normal"
          >
            Find out more details about our approach when it comes to<br />
            project estimation &amp; planning and preparation for the
            development phase.
          </h3>
          <h3
            class="sm:hidden block sm:text-center text-left text-base leading-loose text-grey-text xl:w-full lg:max-w-4xl mx-auto font-normal"
          >
            Find out more details about our approach when it comes to project
            estimation &amp; planning and preparation for the development phase.
          </h3>
          <div
            class="flex flex-wrap mt-3 sm:justify-center justify-start items-center sm:w-full"
          ></div>
        </div>
      </div>
      <router-link :to="{ name: 'Process'}" class="flex justify-center items-center text-center mb-12">
        <button class="btn transparent outline btn-default">
          Find out more
        </button>
      </router-link>
      <section class="flex justify-center items-center">
        <img
          src="img/process/process-img1.svg"
          alt=""
        />
      </section>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="css" scoped></style>
