<template>
  <div
    class="cta-estimate h-full flex flex-col w-full justify-center items-center content-center"
  >
    <span
      class="text-center text-white mx-5 font-semibold text-3xl"
      >Need a successful project?</span
    >
    <button @click="$router.push('/estimate-project')"
      class="btn estimate mt-6 rounded-full white outline btn-default"
    >
      Estimate Project
    </button>
  </div>
</template>

<script>
export default {};
</script>
