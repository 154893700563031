<template>
  <div
    class="flex flex-col justify-start items-center bg-white main overflow-hidden"
  >
    <div class="pb-4 pt-32 px-8">
      <h1
        class="flex justify-left text-3xl flex font-bold leading-small mb-4 text-left sm:text-4xxl sm:justify-center"
      >
        End to End Software Products
      </h1>
      <div>
        <h3
          class="sm:block hidden sm:text-center text-left text-base leading-loose text-grey-text xl:w-full lg:max-w-4xl mx-auto font-normal"
        >
          We help you to take your business to the next level<br
            class="md:hidden block"
          />
          through custom software. <br class="lg:hidden block" />
          Our team worked on<br class="lg:block hidden" />
          end to end products ranging from<br class="lg:hidden block" />
          SaaS, Automation to E-commerce and Open Source.
        </h3>
        <h3
          class="sm:hidden block sm:text-center text-left text-base leading-loose text-grey-text xl:w-full lg:max-w-4xl mx-auto font-normal"
        >
          We help you to take your business to the next level through custom
          software. <br class="sm:hidden block" />
          Our team worked on end to end products ranging from SaaS, Automation
          to E-commerce and Open Source.
        </h3>
        <div
          class="flex flex-wrap mt-3 sm:justify-center justify-start items-center sm:w-full w-64"
        >
          <div>
            <div class="flex items-center justify-center mb-2">
              <span
                class="text-sm leading-normal tracking-medium text-grey-text"
                >E-Commence</span
              >
              <span
                class="sm:block bullet h-1 w-1 inline-block rounded-full bg-primary mx-2"
              ></span>
            </div>
          </div>
          <div>
            <div class="flex items-center justify-center mb-2">
              <span
                class="text-sm leading-normal tracking-medium text-grey-text"
                >Open Source</span
              >
              <span
                class="sm:block bullet h-1 w-1 inline-block rounded-full bg-primary mx-2 hidden"
              ></span>
            </div>
          </div>
          <div>
            <div class="flex items-center justify-center mb-2">
              <span
                class="text-sm leading-normal tracking-medium text-grey-text"
                >Automation</span
              >
              <span
                class="sm:block bullet h-1 w-1 inline-block rounded-full bg-primary mx-2"
              ></span>
            </div>
          </div>
          <div>
            <div class="flex items-center justify-center mb-2">
              <span
                class="text-sm leading-normal tracking-medium text-grey-text"
                >Saas</span
              >
              <span
                class="sm:block bullet h-1 w-1 inline-block rounded-full bg-primary mx-2 sm:hidden hidden"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Products></Products>
    <Estimate></Estimate>
    <FindOut></FindOut>
  </div>
</template>

<script>
import Products from "@/views/subComponents/Products.vue";
import Estimate from "@/views/subComponents/Estimate.vue";
import FindOut from "./components/FindOut.vue";

export default {
  components: {
    Products,
    Estimate,
    FindOut
  }
};
</script>
